const slidesContainerMobile = document.getElementById("slides-container-mobile");
const slideMobile = document.querySelector(".slide-map-mobile");
const prevButtonMobile = document.getElementById("slide-arrow-prev-mobile");
const nextButtonMobile = document.getElementById("slide-arrow-next-mobile");

let counterMobile = 0;
const slidesAmount = 2;

if (counterMobile == 0) {
    prevButtonMobile.style.visibility = 'hidden';
} else if (counterMobile == slidesAmount) {
    nextButtonMobile.style.visibility = 'hidden';
}

nextButtonMobile.addEventListener("click", () => {
  const slideWidth = slideMobile.clientWidth;
  if (counterMobile == slidesAmount) {
    nextButtonMobile.style.visibility = 'hidden';
  } else {
    slidesContainerMobile.scrollLeft += slideWidth;
    prevButtonMobile.style.visibility = 'visible';
    counterMobile++;
  }
});

prevButtonMobile.addEventListener("click", () => {
  const slideWidth = slideMobile.clientWidth;
  if (counterMobile == 0) {
    slidesContainerMobile.scrollRight += (slideWidth * counterMobile);
    prevButtonMobile.style.visibility= 'hidden';
    counterMobile = 0;
  } else {
    slidesContainerMobile.scrollLeft -= slideWidth;
    nextButtonMobile.style.visibility= 'visible';
    counterMobile--;
  }
});